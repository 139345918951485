<template>
  <div class="jw-group-regist-button-box">
    <div class="inner-box">
      <img src="~/assets/images/mng-tab-1.svg" height="21" alt="" />
      <span class="txt">아직 등록되지 않았다면?</span>
      <VBtn
        variant="text"
        color="white"
        class="text-decoration-underline px-0 fs-12"
        @click.stop="$router.push('/more/findMyGroup')"
        data-ko="우리 교회/단체 등록하기"
      >
        우리 교회/단체 등록하기
        <!-- {{ $t("more.regist.church") }} -->
      </VBtn>
    </div>
  </div>
</template>
